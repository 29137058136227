import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";

export default function Resources() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Layout>
      <Container fluid>
        <div className="comn-table-title">
          <h3>
            Resources <span>(12)</span>
          </h3>
          <button onClick={handleShow}>Add New</button>
        </div>
        <div className="songlist-selection mt-3">
          <div className="my-profile-title">
            <h4>Resources</h4>
          </div>
          <hr />
          <div className="resources-card-list">
            <ul>
              <li>
                <h4>Document Title</h4>
                <button>Delete</button>
              </li>
              <li>
                <h4>Document Title</h4>
                <button>Delete</button>
              </li>{" "}
              <li>
                <h4>Document Title</h4>
                <button>Delete</button>
              </li>{" "}
              <li>
                <h4>Document Title</h4>
                <button>Delete</button>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-0">
          <p className="pb-4"></p>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Add Resource</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Form>
              <div className="modal-cmn-form-set">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Document Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter document title"
                  />
                </Form.Group>
              </div>
              <div className="docu-upload-btn">
                <input type="file" />
                <button>Upload File</button>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button className="primary" onClick={handleClose}>
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
