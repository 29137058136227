import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
export default function Administrators() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Layout>
      <Container fluid>
        <div className="comn-table-title">
          <h3>
            Administrators <span>(2)</span>
          </h3>
          {/* <button onClick={handleShow}>Add song</button> */}
        </div>
        <Row>
          <Col lg={8}>
            <div className="filters">
              <div className="inner-filter-field">
                <div class="table-responsive">
                  <Table size="sm" className="table-cmn">
                    <thead>
                      <tr>
                        <th>
                          <div className="user-checkbox">Name</div>
                        </th>
                        <th>Email</th>
                        <th>Permissions</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">Bianca Blezard</div>
                          </div>
                        </td>
                        <td>example@gmail.com</td>
                        <td>Full Access</td>

                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">Bianca Blezard</div>
                          </div>
                        </td>
                        <td>example@gmail.com</td>
                        <td>Full Access</td>

                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">Bianca Blezard</div>
                          </div>
                        </td>
                        <td>example@gmail.com</td>
                        <td>Full Access</td>

                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">Bianca Blezard</div>
                          </div>
                        </td>
                        <td>example@gmail.com</td>
                        <td>Full Access</td>

                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">Bianca Blezard</div>
                          </div>
                        </td>
                        <td>example@gmail.com</td>
                        <td>Full Access</td>

                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">Bianca Blezard</div>
                          </div>
                        </td>
                        <td>example@gmail.com</td>
                        <td>Full Access</td>

                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">Bianca Blezard</div>
                          </div>
                        </td>
                        <td>example@gmail.com</td>
                        <td>Full Access</td>

                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                     
                    </tbody>
                  </Table>
                  <div className="pagination-section">
                    <div className="showing-user">
                      <p>Showing 1 to 10 of 50 entries</p>
                    </div>
                    <div className="pagination-block">
                      <Pagination>
                        {/* <Pagination.First /> */}
                        <Pagination.Prev />
                        <Pagination.Item>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Item active>{3}</Pagination.Item>
                        <Pagination.Item>{4}</Pagination.Item>
                        <Pagination.Next />
                        {/* <Pagination.Last /> */}
                      </Pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="filters">
              <div className="inner-filter-field">
                <div className="add-administrator">
                  <h3>Add Administrator</h3>
                  <div>
                    <Form>
                      <div className="modal-cmn-form-set">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Full Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your Full Name"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email ID</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your Email"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Permissions</Form.Label>
                          <Form.Select aria-label="Default select example">
                            <option>Select Permissions</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </Form>
                  </div>
                  <div className="add-administrator-action">
                    <button className="secondary" onClick={handleClose}>
                      Close
                    </button>
                    <button className="primary" onClick={handleClose}>
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div class="mb-0"><p class="pb-4"></p></div>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Add Song</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Form>
              <div className="modal-cmn-form-set">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Song Title</Form.Label>
                  <Form.Control type="text" placeholder="Enter song title" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Artist</Form.Label>
                  <Form.Control type="text" placeholder="Enter artist name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Genre</Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option>Select Genre</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Form.Group>
                <div className="recommended-w-toggle">
                  <p className="mb-0">Recommended</p>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button className="primary" onClick={handleClose}>
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
