import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useState } from "react";
import OtpInput from "react-otp-input";

export default function OtpVarification() {
  const [otp, setOtp] = useState("");

  return (
    <div className="p-3">
      <Container fluid className="">
        <div className="row  sign-banner-part">
          <Col lg={6} className="p-0">
            <div className="new-otp-banner-img"></div>
          </Col>
          <Col lg={6}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <div className="login-logo">
                    <img
                      src={require("../Assets/Images/dark-logo.png")}
                    />
                  </div>
                  <h2>OTP</h2>
                  <p className="mb-0">
                    We’ve sent an OTP to your registered email.
                    <br />
                    Enter it below to continue.
                  </p>
                  <Form>
                    <div className="form-set set-otp">
                      <OtpInput
                        value={otp}
                        // inputType="number"
                        onChange={setOtp}
                        numInputs={6}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <Link
                      to="/clients"
                      variant="primary"
                      type="submit"
                      className="submit forgot-btn"
                    >
                      Verify
                    </Link>
                    <div className="resend-mail">
                      <p>
                        Didn't get the mail? <Link to="/"> Resend </Link>
                      </p>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
