import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";

export default function Presets() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Layout>
      <Container fluid>
        <div className="comn-table-title">
          <h3>
            Presets <span>(12)</span>
          </h3>
          <button onClick={handleShow}>Create New</button>
        </div>
        <div className="songlist-selection mt-3">
          <div className="my-profile-title">
            <h4>Presets</h4>
          </div>
          <hr />
          <div className="faq-list-accro">
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Wedding Ceremony</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Birthday</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Private Function</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="mt-0">
          <p className="pb-4"></p>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Add Preset</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Form>
              <div className="modal-cmn-form-set">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Event Type</Form.Label>
                  <Form.Control type="text" placeholder="Enter Event Type" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className="sections-add-title">
                    <Form.Label>Sections</Form.Label>
                    <Form.Label>Songs</Form.Label>
                  </div>
                  <div className="sections-add-plus">
                    <Form.Control type="text" placeholder="1. Section Name" />
                    <div className="sections-song">1</div>
                  </div>
                  <div className="sections-add-plus">
                    <Form.Control type="text" placeholder="2. Section Name" />
                    <div className="sections-song">1</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                    >
                      <circle
                        cx="12.7141"
                        cy="12"
                        r="9"
                        stroke="#CCD2E3"
                        stroke-width="2"
                      />
                      <path
                        d="M8.21411 12H17.2141"
                        stroke="#CCD2E3"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                  <div className="sections-add-btn">
                    <button>
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM13 13V17H11V13H7V11H11V7H13V11H17V13H13Z"
                          fill="#979699"
                        />
                      </svg>
                    </button>
                  </div>
                </Form.Group>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button className="primary" onClick={handleClose}>
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
