import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
export default function Transactions() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Layout>
      <Container fluid>
        <div className="comn-table-title">
          <h3>
            Transactions <span></span>
          </h3>
          {/* <button onClick={handleShow}>Create Client</button> */}
        </div>
        <div className="filters">
          <div className="inner-filter-field">
            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        Date
                      </div>
                    </th>
                    <th>Event Name</th>
                    <th>Client Name</th>
                    <th>Amount </th>
                    <th>Status </th>
                    <th>Notes/Details</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">2024-11-15</div>
                      </div>
                    </td>
                    <td>Wedding at Park</td>
                    <td>John Doe</td>
                    <td>€1,200</td>
                    <td>Paid</td>
                    <td>Full payment received</td>
                    <td>
                      <div className="action">
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">2024-11-15</div>
                      </div>
                    </td>
                    <td>Wedding at Park</td>
                    <td>John Doe</td>
                    <td>€1,200</td>
                    <td>Paid</td>
                    <td>Full payment received</td>
                    <td>
                      <div className="action">
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">2024-11-15</div>
                      </div>
                    </td>
                    <td>Wedding at Park</td>
                    <td>John Doe</td>
                    <td>€1,200</td>
                    <td>Paid</td>
                    <td>Full payment received</td>
                    <td>
                      <div className="action">
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">2024-11-15</div>
                      </div>
                    </td>
                    <td>Wedding at Park</td>
                    <td>John Doe</td>
                    <td>€1,200</td>
                    <td>Paid</td>
                    <td>Full payment received</td>
                    <td>
                      <div className="action">
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">2024-11-15</div>
                      </div>
                    </td>
                    <td>Wedding at Park</td>
                    <td>John Doe</td>
                    <td>€1,200</td>
                    <td>Paid</td>
                    <td>Full payment received</td>
                    <td>
                      <div className="action">
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">2024-11-15</div>
                      </div>
                    </td>
                    <td>Wedding at Park</td>
                    <td>John Doe</td>
                    <td>€1,200</td>
                    <td>Paid</td>
                    <td>Full payment received</td>
                    <td>
                      <div className="action">
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">2024-11-15</div>
                      </div>
                    </td>
                    <td>Wedding at Park</td>
                    <td>John Doe</td>
                    <td>€1,200</td>
                    <td>Paid</td>
                    <td>Full payment received</td>
                    <td>
                      <div className="action">
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">2024-11-15</div>
                      </div>
                    </td>
                    <td>Wedding at Park</td>
                    <td>John Doe</td>
                    <td>€1,200</td>
                    <td>Paid</td>
                    <td>Full payment received</td>
                    <td>
                      <div className="action">
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">2024-11-15</div>
                      </div>
                    </td>
                    <td>Wedding at Park</td>
                    <td>John Doe</td>
                    <td>€1,200</td>
                    <td>Paid</td>
                    <td>Full payment received</td>
                    <td>
                      <div className="action">
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">2024-11-15</div>
                      </div>
                    </td>
                    <td>Wedding at Park</td>
                    <td>John Doe</td>
                    <td>€1,200</td>
                    <td>Paid</td>
                    <td>Full payment received</td>
                    <td>
                      <div className="action">
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">2024-11-15</div>
                      </div>
                    </td>
                    <td>Wedding at Park</td>
                    <td>John Doe</td>
                    <td>€1,200</td>
                    <td>Paid</td>
                    <td>Full payment received</td>
                    <td>
                      <div className="action">
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>Showing 1 to 10 of 50 entries</p>
                </div>
                <div className="pagination-block">
                  <Pagination>
                    <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item active>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-0">
          <p className="pb-4"></p>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Create Client</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Form>
              <div className="modal-cmn-form-set">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Full Name*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your Full Name"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email ID*</Form.Label>
                  <Form.Control type="email" placeholder="Enter your email " />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Estimated Date</Form.Label>
                  <Form.Control type="date" placeholder="Select date" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Lorem ipsum dolor sit"
                  />
                </Form.Group>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button className="primary" onClick={handleClose}>
            Create
          </button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
